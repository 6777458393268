import React from 'react';
import { useTranslation } from 'react-i18next';
import {HashLink as Link} from "react-router-hash-link";
import Flag from 'react-flagkit';
import './Header.css'
const Header = ({children}) => {
    const { i18n } = useTranslation();
    const languages = ['en', 'de', 'ua'];

    const getNextLanguageIndex = () => {
        const currentLanguageIndex = languages.indexOf(i18n.language);
        return (currentLanguageIndex + 1) % languages.length;
    };

    const toggleLanguage = () => {
        i18n.changeLanguage(languages[getNextLanguageIndex()]);
    };

    const getNextLanguage = () => {
        return languages[getNextLanguageIndex()];
    };

    const getFlagText = (lang) => {
        switch (lang) {
            case 'en':
                return 'EN';
            case 'de':
                return 'DE';
            case 'ua':
                return 'UA';
            default:
                return 'EN';
        }
    };
 
    return (
        <header className="header">
            <nav className="nav container">
                <a href="index.html" className="nav-logo">Yevgen Panych</a>
                <div className="nav-menu">
                    <ul className="nav-list">
                        <li className="nav-item">
                            <a href="https://www.linkedin.com/in/yevgen-panych/" className="nav-link">
                                <i class="uil uil-linkedin"></i>LinkedIn
                            </a>
                        </li>
                        <li className="nav-item">
                            <a href="https://github.com/iseaman89" className="nav-link">
                                <i class="uil uil-github"></i>GitHub
                            </a>
                        </li>
                        <button className="nav-link" onClick={toggleLanguage}>
                            <i className="bx bx-world"/>{getFlagText(getNextLanguage())}
                        </button>
                    </ul>
                </div>
            </nav>
        </header>
    );
};

export default Header;
