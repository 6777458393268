import React from 'react';
import { useTranslation } from 'react-i18next';
import './Contacts.css'

const Contacts = () => {
    const { t } = useTranslation();
    const handleEmailClick = () => {
        window.location.href = `mailto:iseaman@ukr.net?subject=${encodeURIComponent(t('contacts.emailSubject'))}`;
    };
    
    return (
        <section className="contacts section">
            <h2 className="section-title">{t('contacts.title')}</h2>
            <span className="section-subtitle">{t('contacts.subTitle')}</span>
            <div className="description-container container">
                <div className="section-description">
                    <p className="description-text">{t('contacts.description')}</p>
                    <div className="contacts-img"></div>
                </div>
            </div>
            <div className="contacts-button button-flex" onClick={handleEmailClick}>
                <i className='bx bx-chat contacts-icon'></i>{t('contacts.button')}
            </div>
        </section>
    );
};

export default Contacts;