import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import TypeWriter from "react-ts-typewriter";
import { useInView } from 'react-intersection-observer';
import './Education.css'

const Education = () => {
    const { t } = useTranslation();
    const [toggleState, setToggleState] = useState(1);
    const [startTyping, setStartTyping] = useState(false);
    const toggleTab = (index) => {
        setToggleState(index)
    }

    const { ref, inView } = useInView({
        threshold: 0.1,
    });

    React.useEffect(() => {
        if (inView) {
            setStartTyping(true);
        } else {
            setStartTyping(false);
        }
    }, [inView]);
    
    return (
        <section id='education' className='education-section'>
            <h2 className="section-title">{t('education.title')}</h2>
            <span className="section-subtitle">{t('education.subTitle')}</span>
            
            <div className="education-container container">
                <div className="education-tabs">
                    <div className={toggleState === 1 ? "education-button education-active button-flex" : "education-button button-flex"} onClick={() => toggleTab(1)}>
                        <i className="bx bxs-school education-icon"></i>{t('education.titleSchool')}
                    </div>
                    <div className={toggleState === 2 ? "education-button education-active button-flex" : "education-button button-flex"} onClick={() => toggleTab(2)}>
                        <i className="bx bxs-graduation education-icon"></i>{t('education.titleUni')}
                    </div>
                    <div className={toggleState === 3 ? "education-button education-active button-flex" : "education-button button-flex"} onClick={() => toggleTab(3)}>
                        <i className="bx bx-hard-hat education-icon"></i>{t('education.titleProfSchool')}
                    </div>
                </div>

                <div ref={ref} className="education-sections">
                    <div className={toggleState === 1 ? "education-content education-content-active" : "education-content"}>
                        <div className="education-data">
                            <div className="education-img school-img"></div>
                            <div className="education-text">
                                <h3 className="education-title">{t('education.titleSchool')}</h3>
                                {startTyping && toggleState === 1 && (
                                    <p className="education-description">
                                        {t('education.descriptionSchool')}
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>

                    <div
                        className={toggleState === 2 ? "education-content education-content-active" : "education-content"}>
                        <div className="education-data">
                            <div className="education-img uni-img"></div>
                            <div className="education-text">
                                <h3 className="education-title">{t('education.titleUni')}</h3>
                                {startTyping && toggleState === 2 && (
                                    <p className="education-description">
                                        {t('education.descriptionUni')}
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>

                    <div
                        className={toggleState === 3 ? "education-content education-content-active" : "education-content"}>
                        <div className="education-data">
                            <div className="education-img profschool-img"></div>
                            <div className="education-text">
                                <h3 className="education-title">{t('education.titleProfSchool')}</h3>
                                {startTyping && toggleState === 3 && (
                                    <p className="education-description">
                                        {t('education.descriptionProfSchool')}
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Education;