import React from 'react';
import Fullpage, {FullPageSections, FullpageSection, FullpageNavigation} from '@ap.cx/react-fullpage';
import About from './components/About/About';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Contacts from './components/Contacts/Contacts';
import Education from './components/Education/Education';
import Projects from './components/Projects/Projects';
import Skills from './components/Skills/Skills';

const App = () => {
    return (
        <>
            <Header/>
            <main className='main'>
                <Fullpage>
                    <FullpageNavigation/>
                    <FullPageSections>
                        <FullpageSection>
                            <About/>
                        </FullpageSection>
                        <FullpageSection>
                            <Education/>
                        </FullpageSection>
                        <FullpageSection>
                            <Skills/>
                        </FullpageSection>
                        <FullpageSection>
                            <Projects/>
                        </FullpageSection>
                        <FullpageSection>
                            <Contacts/>
                        </FullpageSection>
                    </FullPageSections>
                </Fullpage>
            </main>
            <Footer/>
        </>
    );
}

export default App;
