import React from 'react';
import { useTranslation } from 'react-i18next';
import TypeWriter from 'react-ts-typewriter';
import './About.css'

const About = () => {
    const { t } = useTranslation();
    return (
        <section className='about section' id='about'>
            <div className="about-container container grid">
                <div className="about-content grid">
                    <div className="about-text">
                        <h2 className="about-title">{t('about.title')}</h2>
                        <h3 className="about-subtitle">{t('about.subTitle')}</h3>
                        <p className="about-description">{t('about.description')}</p>
                    </div>
                    <div className="about-img"></div>
                </div>
            </div>
        </section>
    );
};

export default About;
