import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import './Skills.css'

const Skills = () => {
    const { t } = useTranslation();
    const [toggleState, setToggleState] = useState(1);
    const toggleTab = (index) => {
        setToggleState(index)
    }
    
    const skills = [
        {icon: 'azuresqldatabase-plain', percentage: 60, text: t('skills.gradation.good')},
        {icon: 'blazor-original', percentage: 80, text: t('skills.gradation.notProf')},
        {icon: 'cplusplus-plain', percentage: 20, text: t('skills.gradation.basic')},
        {icon: 'csharp-plain', percentage: 99, text: t('skills.gradation.notProf')},
        {icon: 'css3-plain', percentage: 80, text: t('skills.gradation.notProf')},
        {icon: 'docker-plain', percentage: 50, text: t('skills.gradation.good')},
        {icon: 'dotnetcore-plain', percentage: 80, text: t('skills.gradation.notProf')},
        {icon: 'git-plain', percentage: 80, text: t('skills.gradation.notProf')},
        {icon: 'html5-plain', percentage: 80, text: t('skills.gradation.notProf')},
        {icon: 'javascript-plain', percentage: 40, text: t('skills.gradation.basicPlus')},
        {icon: 'jira-plain', percentage: 80, text: t('skills.gradation.notProf')},
        {icon: 'mysql-plain-wordmark', percentage: 60, text: t('skills.gradation.good')},
        {icon: 'postgresql-plain-wordmark', percentage: 60, text: t('skills.gradation.good')},
        {icon: 'python-plain', percentage: 40, text: t('skills.gradation.basicPlus')},
        {icon: 'react-original', percentage: 40, text: t('skills.gradation.basicPlus')},
        {icon: 'rider-plain', percentage: 80, text: t('skills.gradation.notProf')},
        {icon: 'unity-plain', percentage: 90, text: t('skills.gradation.notProf')},
        {icon: 'visualstudio-plain', percentage: 80, text: t('skills.gradation.notProf')},
        {icon: 'xamarin-original', percentage: 60, text: t('skills.gradation.good')},
        {icon: 'dot-net-plain-wordmark', percentage: 80, text: t('skills.gradation.notProf')}
    ]
    
    return (
        <section className="skills-section" id="section">
            <h2 className="section-title">{t('skills.title')}</h2>
            <span className="section-subtitle">{t('skills.subTitle')}</span>

            <div className="skills-container container">
                <div className="skills-tabs">
                    <div
                        className={toggleState === 1 ? "skills-button skills-active button-flex" : "skills-button button-flex"}
                        onClick={() => toggleTab(1)}>
                        <i className="uil uil-percentage  skills-icon"></i>
                        <span className="skilld-menu-text">{t('skills.percent')}</span>
                    </div>
                    <div
                        className={toggleState === 2 ? "skills-button skills-active button-flex" : "skills-button button-flex"}
                        onClick={() => toggleTab(2)}>
                        <i className="uil uil-minus skills-icon"></i>
                        <span className="skilld-menu-text">{t('skills.line')}</span>
                    </div>
                    <div
                        className={toggleState === 3 ? "skills-button skills-active button-flex" : "skills-button button-flex"}
                        onClick={() => toggleTab(3)}>
                        <i className="uil uil-text skills-icon"></i>
                        <span className="skilld-menu-text">{t('skills.letter')}</span>
                    </div>
                </div>
                
                <div className="skills-sections">
                    <div className="skills-content">
                        <div className="skills-data">
                            {skills.map((skill, index) => (
                                <div key={index} className="skill-item">
                                    <i className={`devicon-${skill.icon} skill-icon`}></i>
                                    {toggleState === 1 ? (
                                        <div className="skill-text-container">
                                            <p className="skill-text">{skill.percentage}%</p>
                                        </div>
                                    ) : toggleState === 2 ? (
                                        <div className="skill-bar-container">
                                            <div className="skill-bar" style={{width: `${skill.percentage}%`}}></div>
                                        </div>
                                    ) : (
                                        <div className="skill-text-container">
                                            <p className="skill-text">{skill.text}</p>
                                        </div>
                                    )}

                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Skills;