import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import {Swiper, SwiperSlide, useSwiper} from 'swiper/react';
import { Navigation, Pagination, Scrollbar, EffectCards } from 'swiper/modules';
import 'swiper/css/bundle';
import './Projects.css'
import Typewriter from 'typewriter-effect';
import ImageTest from '../../assets/home.png';

const Projects = () => {
    const { t } = useTranslation();
    const [startTyping, setStartTyping] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const swiper = useSwiper();
    const { ref, inView } = useInView({
        threshold: 0.1,
    });

    const projectsArray = [
        {
            title: t('projects.projectsModal.flappy.title'),
            description: t('projects.projectsModal.flappy.description'),
            img: ImageTest
        },
        {
            title: t('projects.projectsModal.licensePlate.title'),
            description: t('projects.projectsModal.licensePlate.description'),
            img: "../../assets/home.png"
        },
        {
            title: t('projects.projectsModal.portfolio.title'),
            description: t('projects.projectsModal.portfolio.description'),
            img: "../../assets/home.png"
        }
    ];

    React.useEffect(() => {
        if (inView) {
            setStartTyping(true);
        } else {
            setStartTyping(false);
        }
    }, [inView]);
    
    return (
        <section className="projects-section section">
            <h2 className="section-title">{t('projects.title')}</h2>
            <span className="section-subtitle">{t('projects.subTitle')}</span>

            <div ref={ref} className="description-section">
                <div className="description-container container">
                    <div className="description-data">
                        <div className="description-text-container">
                            <h3 className="description-title"></h3>
                            <p className="description-text">{t('projects.description')}</p>
                        </div>
                        <div className="description-img">
                            {startTyping && (
                                <p className="img-text">
                                    <Typewriter options={{delay: 65, cursor: ''}} onInit={(typewriter) => {
                                        typewriter.typeString('<span class="violet-word">public class </span><span class="orange-word">MyNewCoolProgram</span><br>')
                                            .typeString('{<br>')
                                            .typeString('  <span class="violet-word">var</span> data = <span class="orange-word">DataCopy</span>.<span class="blue-word">CopyDataFromLink</span>(<span class="green-word">"google.com"</span>);<br>')
                                            .typeString('<br>')
                                            .typeString('  <span class="violet-word">var</span> myBetterGoogle = <span class="orange-word">Updater</span>.<span class="blue-word">UpdateAndMakeBetter</span>(data);<br>',)
                                            .typeString('<br>')
                                            .typeString('  <span class="orange-word">Uploader</span>.<span class="blue-word">UploadSiteInWeb</span>(myBetterGoogle);<br>')
                                            .typeString('<br>')
                                            .typeString('  <span class="orange-word">App</span>.<span class="blue-word">RunProgramAndMakeMoney</span>();<br>')
                                            .typeString('}')
                                            .start()
                                    }}/>
                                </p>
                            )}
                        </div>
                    </div>

                </div>
            </div>
            <div className="projects-button button-flex" onClick={() => setShowModal(!showModal)}>
                <i className='bx bx-shocked projects-icon'></i>{t('projects.button')}
            </div>
            
            <Swiper slidesPerView={1}
                    loop={true}
                    spaceBetween={450}
                    modules={[Navigation]}
                    navigation={{
                        enabled: true,
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    }}
                    grabCursor={false}
                    className={showModal ? "projects-modal active-modal" : "projects-modal"}
                    >
                {projectsArray.map((project, index) => (
                    <SwiperSlide key={index} className="projects-modal-content">
                        <i className="uil uil-times projects-modal-close" onClick={() => setShowModal(!showModal)}></i>
                        <button className="swiper-button-prev"></button>
                        <div className="projects-modal-text">
                            <h3 className="projects-modal-title">{project.title}</h3>
                            <p className="projects-modal-description">{project.description}</p>
                        </div>
                        <button className="swiper-button-next"></button>
                    </SwiperSlide>
                ))}
            </Swiper>

        </section>
    );
};

export default Projects;